export default {
  brand: '#fc9003',
  orange: {
    50: '#fff6da',
    100: '#ffe3ad',
    200: '#ffd07d',
    300: '#ffbd4b',
    400: '#ffaa1a',
    500: '#e69000',
    600: '#b37000',
    700: '#815000',
    800: '#4e2f00',
    900: '#1f0f00',
  },
  cyan: {
    50: '#e2f6ff',
    100: '#bedef3',
    200: '#97c7e6',
    300: '#71b1db',
    400: '#4b9acf',
    500: '#3281b6',
    600: '#24648e',
    700: '#174767',
    800: '#082b40',
    900: '#000f1a',
  },
}
