import { extendTheme } from '@chakra-ui/react'
import foundations from './foundations'
import components from './components'

const config = {
  useSystemColorMode: false,
  initialColorMode: 'light',
}

const customTheme = extendTheme({
  config,
  styles: {
    global: {
      body: {
        fontWeight: 'light',
      },
      '::-moz-selection': {
        color: 'cyan.900',
        background: 'cyan.300',
      },
      '::selection': {
        color: 'cyan.900',
        background: 'cyan.300',
      },
    },
  },
  ...foundations,
  components: {
    ...components,
  },
  textStyles: {
    h2: {
      fontSize: ['xl', '2xl'],
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    h3: {
      fontSize: ['md', 'md'],
      fontWeight: 'bold',
    },
    body: {
      fontWeight: 'light',
    },
    hero: {
      fontSize: ['xl', '2xl'],
      fontWeight: 'light',
    },
  },
})

export default customTheme
