/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react'
import {
  ThemeProvider,
  ColorModeProvider,
  CSSReset,
  GlobalStyle,
  PortalManager,
} from '@chakra-ui/react'
import theme from './src/theme'

export const wrapRootElement = ({ element }) => {
  const content = (
    <>
      <CSSReset />
      <GlobalStyle />
      <PortalManager zIndex={40}>{element}</PortalManager>
    </>
  )
  return (
    <ThemeProvider theme={theme}>
      <ColorModeProvider options={theme.config}>{content}</ColorModeProvider>
    </ThemeProvider>
  )
}
